<template>
  <div>
    <ShowBookingQuestionsStep />
  </div>
</template>

<script>
  import ShowBookingQuestionsStep from '@/components/Book/show/ShowBookingQuestionsStep'

  export default {
    name: 'ShowBookingQuestions',

    components: {
      ShowBookingQuestionsStep,
    },

    computed: {
      steps() {
        return this.$store.getters['showBooking/enabledSteps']
      },
    },

    methods: {
      determineStepNumber() {
        let step = this.steps.findIndex(step => step.name == 'questions')

        if (step === -1) {
          return this.$router.push({ name: this.steps[0].route })
        }

        this.$store.commit('showBooking/setCurrentStep', step + 1)
      }
    },

    mounted() {
      this.determineStepNumber()
    }
  }
</script>
