<template>
  <div class="min-h-full flex flex-col">
    <div class="flex justify-between items-center mb-10">
      <div class="flex flex-col">
        <h4 class="text-3xl sm:text-4xl font-bold leading-tight">
          Application
        </h4>
        <div class="mt-6 sm:mt-10 text-gray-500 font-light text-xl">
          {{ show.name }} wants to ask you some questions
        </div>
      </div>
      <!-- Step counter -->
      <StepsCounter class="ml-8 mb-32" v-if="steps.length" :stepCount="steps.length" :currentStep="currentStep"/>
    </div>

    <ValidationObserver ref="questionsForm" v-slot="{ handleSubmit, invalid }">
      <form method="post" @submit.prevent="handleSubmit(saveAnswers)">
        <div class="grid grid-cols-1 row-gap-6">
          <div v-for="(question, index) in form" :key="`question-${index}`">
            <label class="font-semibold text-black">
              {{ question.question }}
            </label>
            <ValidationProvider rules="required" :name="`Question ${index + 1}`" :vid="`question_${index + 1}`" v-slot="{ errors }">
              <div class="mt-2">
                <textarea rows="5" class="form-input text-black bg-gray-50 w-full px-5 py-3 rounded-xl border-transparent focus:bg-white" v-model="question.answer"></textarea>
              </div>
              <p class="text-red-500 text-sm mt-1 font-light" v-if="errors.length">{{ errors[0] }}</p>
            </ValidationProvider>
          </div>
        </div>
        <div class="mt-12 flex justify-center">
          <button type="submit" class="rounded-full h-16 sm:h-20 bg-indigo-gradiant text-white text-xl font-bold flex justify-center items-center uppercase px-16 disabled:opacity-50" :disabled="loading || invalid">
            <span v-if="loading">
              <loading-icon class="h-5 w-5"/>
            </span>
            <span v-else>Continue</span>
          </button>
        </div>
        <div class="text-center mt-4">
          <button @click.prevent="prevStep" type="button" class="text-sm text-pink-500 hover:underline">Previous step</button>
        </div>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
  import StepsCounter from '@/components/StepsCounter'

  export default {
    name: 'ShowBookingQuestionsStep',

    components: {
      StepsCounter
    },

    data() {
      return {
        loading: false,
        form: [],
      }
    },

    computed: {
      steps() {
        return this.$store.getters['showBooking/enabledSteps']
      },

      currentStep() {
        return this.$store.state.showBooking.currentStep
      },

      questions() {
        return this.$store.state.showBooking.questions
      },

      user() {
        return this.$store.getters['auth/getUser']
      },

      guest() {
        return this.$store.getters['showBooking/guest']
      },

      show() {
        return this.$store.getters['shows/show']
      },


      selectedBookingOption() {
        return this.$store.getters['showBooking/selectedBookingOption']
      },

      pitch() {
        return this.$store.getters['showBooking/pitch']
      },
    },

    methods: {
      saveAnswers() {
        this.$store.commit('showBooking/setQuestions', this.form)

        this.nextStep()
      },

      nextStep() {
        let nextStepRoute = this.steps[this.currentStep].route

        this.$router.push({ name: nextStepRoute })
      },

      prevStep() {
        let prevStepRoute = this.steps[this.currentStep - 2].route

        this.$router.push({ name: prevStepRoute })
      },
    },

    mounted() {
      if (! this.guest || ! this.guest.id) {
        this.$router.push({name: this.steps[0].route})
        return
      }

      if (! this.selectedBookingOption) {
        this.$router.push({name: 'ShowBookingOptions'})
        return
      }

      if (this.selectedBookingOption.slug === 'pitch' && ! this.pitch) {
        this.$router.push({name: 'ShowBookingPitch'})
        return
      }

      this.questions.forEach(q => this.form.push({
        ...q,
      }))
    }
  }
</script>
